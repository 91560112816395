import React from "react";
import { useState, useEffect } from "react";
import "./style.css";
import Header from "./Header";
import Footer2 from "./Footer2";
// import UserformSurvey from "./mattress_questionnare";
import { Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import Recaptcha from "react-recaptcha";
import { Helmet } from "react-helmet";
import LogService from "../services/log.service";

// import axios from "axios";

function Index(props) {
  const TITLE = "Raymour & Flanigan | Register";
  const navigate = useNavigate();
  const [website, setWebsite] = useState(window.location.href);

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  const submitForm = (e) => {
    e.preventDefault();
    localStorage.setItem("return_page", "true");
    // props.history.push("/register/RegisterForm");
    navigate("/register/registration-form");
    // <--- The page you want to redirect your user to.
  };

  // if (localStorage.getItem("return_page") === "true") {
  //   return <Navigate to="/register/registration-form" />;
  // }

  localStorage.setItem("return_page", "false");
  localStorage.setItem("TestOurTech_thankyou", "false");

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        <Header />
        <div className="white-box">
          <ul className="points">
            <form
              method="post"
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submitForm}
            >
              <h2
                className="register_lower"
                style={{ textAlign: "center", fontFamily: "Nimbus Sans" }}
              >
                Raymour & Flanigan and Elite Rewards are proud sponsors of Make
                A Wish.
              </h2>
              <div className="row">
                <div class="col-md-12" id="text">
                  <p>
                    {" "}
                    Would you be interested in giving 8-10 minutes of your time
                    to improve your sleep?
                    <br />
                    You will get a $25 Reward Card when you complete a comfort
                    test, and we'll also give a portion of the proceeds to Make
                    A Wish. Just a few moments of your time can help the way you
                    sleep.
                  </p>
                </div>
              </div>
              <br />
              <input
                // onClick="myTimeout()"
                type="submit"
                name="submit"
                id="submit1"
                value="CONTINUE"
                className="sub-btn button"
              ></input>
            </form>
          </ul>
        </div>
      </div>

      <Footer2 />
    </>
  );
}
export default Index;
