import React from "react";
import ParrotLogo1 from '../images/parrottestrest-logo1.png';
function Header() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="logo">
          <img
            src={ParrotLogo1}
            alt="sleepwellsville-logo.png"
            className="img-responsive"
          />
        </div>
      </div>
    </div>
  );
}
export default Header;
