import { React, useRef, useState, useEffect } from "react";
import Header from "./Header";
import Footer2 from "./Footer2";
import HeaderLower from "./HeaderLower";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";
// import Recaptcha from "react-recaptcha";
import RegisterService from "../services/register.service";
import LogService from "../services/log.service";
import StorageService from "../services/storage.service";
import { Navigate, Redirect, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import $ from "jquery";
var he = require("he");

// import PreLoader from "./PreLoader";
function RegisterForm(props) {
  const TITLE = "Raymour & Flanigan Cares | Registration Form";
  const [fields, setFields] = useState({
    sales_name_f: "",
    sales_name_l: "",
    store_location: "",
    title: "",
    name: "",
    lname: "",
    emailid: "",
    phone: "",
    card_amount: "",
    order_no: "",
    customer_code: "",
    reason_code: "",
    desc_issue: "",
  });

  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [amtErr, setAmtErr] = useState("");
  const [place, setPlace] = useState("Enter Amount $");
  const [errors, setErrors] = useState({});
  const [titles, setTitles] = useState([]);

  // const [captcha, setCaptcha] = useState(true);
  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);
  const [value_userform, setValue_userform] = useState([]);
  const [website, setWebsite] = useState(window.location.href);
  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const preventRange = (e) => {
    var min = parseInt(e.target.min);
    var max = parseInt(e.target.max);
    var value = parseInt(e.target.value);

    if (value > max) {
      e.target.value = max;
    }

    if (value < min) {
      e.target.value = min;
    }
  };

  const changeTitle = async (e) => {
    // $(".card_amount").val("");
    fields.card_amount = "";
    setMin("");
    setMax("");
    setPlace(`Enter Amount $`);
    if (e != "") {
      try {
        const response = await RegisterService.getRangeByTitle(e);
        const { data } = response;
        const { data: nestedData } = data;
        const { min: minRes, max: maxRes } = nestedData;
        setMin(minRes);
        setMax(maxRes);
        if (maxRes) {
          setPlace(`Enter Amount $${minRes} - $${maxRes}`);
        } else {
          setPlace(`Enter Amount $${minRes} - $ Unlimited`);
        }
      } catch (err) {
        setMin("");
        setMax("");
        setPlace(`Enter Amount $`);
      }
    }
  };

  const destroy = () => {
    localStorage.setItem("returnpage", true);
    localStorage.removeItem("return_page", false);
    localStorage.removeItem("registerForm");
    window.location.replace("/register");
  };

  // StorageService.setRegister(false);
  localStorage.setItem("isRegister", true);

  const ref = useRef();
  useEffect(() => {
    async function test() {
      let errors = {};

      try {
        //FIXME remove this service if not used
        const response = await RegisterService.getTitles();
        var value = response.data.data;
        setTitles(value);
        if (response.data.status === 429 || response.data.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
        if (value.includes("redirect")) {
        } else {
          errors["questionnare"] = response.data.response;
        }
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }

    test();
  }, []);

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "title" && value === "true") {
      value = "";
    }
    if (name === "reason_code" && value === "true") {
      value = "";
    }
    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  // const verifyCallback = (response) => {
  //   let errors = {};
  //   if (response) {
  //     setCaptcha({
  //       captcha: true,
  //     });
  //   } else {
  //     setErrors(errors);
  //     return false;
  //   }
  // };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      let data = {
        sales_name_f: fields?.sales_name_f,
        sales_name_l: fields?.sales_name_l,
        store_location: fields?.store_location,
        title: fields?.title,
        fname: fields?.name,
        lname: fields?.lname,
        email: fields?.emailid,
        phone: fields?.phone,
        card_amount: parseInt(fields?.card_amount),
        order_no: fields?.order_no,
        customer_code: fields?.customer_code,
        reason_code: fields?.reason_code,
        desc_issue: fields?.desc_issue,
      };

      try {
        try {
          LogService.createFormLog(website, "POST", data);
        } catch (e) {
          console.log(e);
        }

        const responseuserform = RegisterService.regData(data);
        responseuserform
          .then((response) => {
            LogService.apiResLog(website, JSON.stringify(response.data));

            var valueUserform = response?.data?.status;
            setValue_userform(valueUserform);

            // StorageService.setRegister(true);
            localStorage.removeItem("return_page");

            swal({
              title: "Successful!",
              text: "Form Submitted!",
              type: "success",
              icon: "success",
            }).then(() => {
              secureLocalStorage.setItem(
                "registerform",
                JSON.stringify({
                  sales_name_f: fields.sales_name_f,
                  sales_name_l: fields.sales_name_l,
                  store_location: fields.store_location,
                  title: fields.title,
                  name: fields.name,
                  lname: fields.lname,
                  emailid: fields.emailid,
                  phone: fields.phone,
                  card_amount: fields.card_amount,
                  order_no: fields.order_no,
                  customer_code: fields.customer_code,
                  reason_code: fields.reason_code,
                  desc_issue: fields.desc_issue,
                })
              );
              navigate("/register/registration-thankyou");
            });
            if (value_userform?.includes("redirect")) {
            }
          })
          .catch((err) => {
            LogService.apiResLog(website, JSON.stringify(err.response.data));
            // if (err.response.status == 416) {
            //   setAmtErr("");
            //   // errorData["card_amount1"] = err?.response?.data?.message;
            //   setAmtErr(err?.response?.data?.message);
            //   // swal(err.response.data.message);
            // } else {
            // setAmtErr("");
            let errorData = {};
            errorData["sales_name_f"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.sales_name_f &&
              err?.response?.data?.message.sales_name_f[0];
            errorData["sales_name_l"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.sales_name_l &&
              err?.response?.data?.message.sales_name_l[0];
            errorData["store_location"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.store_location &&
              err?.response?.data?.message.store_location[0];
            errorData["title"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.title &&
              err?.response?.data?.message.title[0];
            errorData["name"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.fname &&
              err?.response?.data?.message.fname[0];
            errorData["lname"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.lname &&
              err?.response?.data?.message.lname[0];
            errorData["emailid"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.email &&
              err?.response?.data?.message.email[0];
            errorData["phone"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.phone &&
              err?.response?.data?.message.phone[0];
            errorData["card_amount"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.card_amount &&
              err?.response?.data?.message.card_amount[0];
            errorData["order_no"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.order_no &&
              err?.response?.data?.message.order_no[0];
            errorData["customer_code"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.customer_code &&
              err?.response?.data?.message.customer_code[0];
            errorData["reason_code"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.reason_code &&
              err?.response?.data?.message.reason_code[0];
            errorData["desc_issue"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.desc_issue &&
              err?.response?.data?.message.desc_issue[0];
            errorData["checkederrortwo"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.size &&
              err?.response?.data?.message.size[0];
            saveErrors(errorData);
            ref?.current?.focus();
            // }
          });
      } catch (err) {
        alert(err);
        if (err.response.status === 429 || err.response.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
        let errorData = {};
        errorData["sales_name_f"] =
          err?.response?.data?.message.sales_name_f[0];
        errorData["sales_name_l"] =
          err?.response?.data?.message.sales_name_l[0];
        errorData["store_location"] =
          err?.response?.data?.message.store_location[0];
        errorData["title"] = err?.response?.data?.message.title[0];
        errorData["name"] = err?.response?.data?.message.fname[0];
        errorData["lname"] = err?.response?.data?.message.lname[0];
        errorData["emailid"] = err?.response?.data?.message.emailid[0];
        errorData["phone"] = err?.response?.data?.message.phone[0];
        errorData["card_amount"] = err?.response?.data?.message.card_amount[0];
        errorData["order_no"] = err?.response?.data?.message.order_no[0];
        errorData["customer_code"] =
          err?.response?.data?.message.customer_code[0];
        errorData["reason_code"] = err?.response?.data?.message.reason_code[0];
        errorData["desc_issue"] = err?.response?.data?.message.desc_issue[0];

        saveErrors(errorData);
      }
    }
  };
  const validate = (e) => {
    let errors = {};
    let isformIsValid = true;

    // if (!captcha) {
    //   isformIsValid = false;
    //   errors["checkederrorcaptcha"] = "Captcha not verified";
    //   saveErrors(errors);
    //   return isformIsValid;
    // }

    return isformIsValid;
  };
  // if (localStorage.getItem("return_page") !== "true") {
  //   return <Navigate to="/register" />;
  // }
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <PreLoader/> */}
        <Header />
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form" id="font-style">
            {/* <h1>Register to receive your free reward card!</h1>
            <hr /> */}
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
              // onClick={this.popup}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Associate First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Associate First Name"
                    style={errors.gers ? { border: "1px solid red" } : null}
                    name="sales_name_f"
                    id="sales_name_f"
                    className="searchBox_deals vc-validate"
                    ref={errors.sales_name_f ? ref : null}
                    value={fields.sales_name_f || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.sales_name_f}</div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Associate Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Associate Last Name"
                    style={
                      errors.sales_name_l && !errors.sales_name_f
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="sales_name_l"
                    id="sales_name_l"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.sales_name_l && !errors.sales_name_f ? ref : null
                    }
                    value={fields.sales_name_l || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.sales_name_l && !errors.sales_name_f
                      ? errors.sales_name_l
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Associate Location <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Associate Location"
                    style={
                      errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="store_location"
                    id="store_location"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? ref
                        : null
                    }
                    value={fields.store_location || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f
                      ? errors.store_location
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Associate Title<span className="red_point"> *</span>
                  </label>
                  <select
                    style={
                      errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? ref
                        : null
                    }
                    name="title"
                    id="title"
                    className="select_1 dropdown"
                    autoFocus=""
                    onChange={(e) => {
                      handleChange(e);
                      changeTitle(e.target.value);
                    }}
                  >
                    <option value="">Select Associate Title</option>
                    {titles.map((option) => {
                      let optionVal = `${he.decode(option.title)} (no limit)`;
                      if (option.title !== "Director&sol;VP")
                        optionVal = `${he.decode(option.title)} (up to $${
                          option.max
                        })`;
                      return (
                        <>
                          <option value={option.title}>{optionVal}</option>
                        </>
                      );
                    })}
                  </select>
                  <div className="errorMsg">
                    {errors.title &&
                    !errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f
                      ? errors.title
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter First Name"
                    style={
                      errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    name="name"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? ref
                        : null
                    }
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.name &&
                    !errors.title &&
                    !errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f
                      ? errors.name
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Enter Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={
                      errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname &&
                    !errors.name &&
                    !errors.title &&
                    !errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f
                      ? errors.lname
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email Address <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    ref={
                      errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    id="emailid"
                    placeholder="Enter Email Address"
                    value={fields.emailid || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.title &&
                    !errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone# <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="phone"
                    placeholder="Enter Phone#"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {" "}
                    {errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.title &&
                    !errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f
                      ? errors.phone
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Reward Card Amount <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="card_amount"
                    className="card_amount"
                    style={
                      errors.card_amount &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="card_amount"
                    placeholder={place}
                    value={fields.card_amount || ""}
                    ref={
                      errors.card_amount &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={(e) => {
                      onlyNumbers(e);
                      preventRange(e);
                    }}
                    onPaste={pasted}
                    min={min}
                    max={max}
                  />
                  <div className="errorMsg">
                    {" "}
                    {errors.card_amount &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.title &&
                    !errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f
                      ? errors.card_amount
                      : null}
                  </div>
                  {/* <div className="errorMsg">{amtErr}</div> */}
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Order# <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="order_no"
                    style={
                      errors.order_no &&
                      !errors.card_amount &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="order_no"
                    placeholder="Enter Order#"
                    value={fields.order_no || ""}
                    ref={
                      errors.order_no &&
                      !errors.card_amount &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    // onKeyUp={onlyNumbers}
                    // onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {" "}
                    {errors.order_no &&
                    !errors.card_amount &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.title &&
                    !errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f
                      ? errors.order_no
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Code <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="customer_code"
                    style={
                      errors.customer_code &&
                      !errors.order_no &&
                      !errors.card_amount &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Enter Customer Code"
                    id="customer_code"
                    className="searchBox_deals vc-validate"
                    value={fields.customer_code || ""}
                    ref={
                      errors.customer_code &&
                      !errors.order_no &&
                      !errors.card_amount &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.customer_code &&
                    !errors.order_no &&
                    !errors.card_amount &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.title &&
                    !errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f
                      ? errors.customer_code
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Reason Code<span className="red_point"> *</span>
                  </label>
                  <select
                    style={
                      errors.reason_code &&
                      !errors.customer_code &&
                      !errors.order_no &&
                      !errors.card_amount &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.reason_code &&
                      !errors.customer_code &&
                      !errors.order_no &&
                      !errors.card_amount &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? ref
                        : null
                    }
                    name="reason_code"
                    id="reason_code"
                    className="select_1 dropdown"
                    autoFocus=""
                    onChange={handleChange}
                  >
                    <option value>Select Reason Code</option>
                    <option value="Social Media">Social Media</option>
                    <option value="Net Promoter Survey">
                      Net Promoter Survey
                    </option>
                    <option value="Delivery">Delivery</option>
                    <option value="Service">Service</option>
                    <option value="Sales">Sales</option>
                    <option value="Other">Other</option>
                  </select>
                  <div className="errorMsg">
                    {errors.reason_code &&
                    !errors.customer_code &&
                    !errors.order_no &&
                    !errors.card_amount &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.title &&
                    !errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f
                      ? errors.reason_code
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Description of Customer Issue
                    <span className="red_point"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Customer Issue"
                    id="desc_issue"
                    name="desc_issue"
                    value={fields.desc_issue || ""}
                    onChange={handleChange}
                    rows="3"
                    style={
                      errors.desc_issue &&
                      !errors.reason_code &&
                      !errors.customer_code &&
                      !errors.order_no &&
                      !errors.card_amount &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.desc_issue &&
                      !errors.reason_code &&
                      !errors.customer_code &&
                      !errors.order_no &&
                      !errors.card_amount &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name &&
                      !errors.title &&
                      !errors.store_location &&
                      !errors.sales_name_l &&
                      !errors.sales_name_f
                        ? ref
                        : null
                    }
                  ></textarea>
                  <div className="errorMsg">
                    {errors.desc_issue &&
                    !errors.reason_code &&
                    !errors.customer_code &&
                    !errors.order_no &&
                    !errors.card_amount &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.title &&
                    !errors.store_location &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f
                      ? errors.desc_issue
                      : null}
                  </div>
                </div>
                <br />

                <div className="clearfix"></div>
              </div>

              <div className="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                onClick="myTimeout()"
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn2 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default RegisterForm;
